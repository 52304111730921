<template>
  <div class="public">
    <div class="container HomePlace">
      <!-- 四色码 -->
      <div class="idiot-one">
        <div class="idiot-child-top">
          <div class="idiot-child-top-box">
            <div v-for="(item, i) in liuchengdata" :key="i">
              <div :class="`idiot-child-top-pic pic${i + 1}`"></div>
              <div
                :class="`idiot-child-top-name ${
                  i === 2 || i === 3 || i === 4 ? 'long' : ''
                }`"
              >
                {{ item.name }}
              </div>
              <div class="idiot-child-top-num">
                {{ newforCodeData[i].num
                }}<span class="idiot-child-top-num-span">{{ "次" }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="box-idiot">
          <div class="box" v-for="(item, i) in newforCodeData" :key="i">
            <div class="box-one">
              <span>较昨日：</span>
              <span>{{ item.yestodayNum }}</span>
              <span
                ><i
                  :class="
                    item.yestodayNum > 0
                      ? 'el-icon-top t'
                      : item.yestodayNum < 0
                      ? 'el-icon-bottom b'
                      : ''
                  "
                ></i
              ></span>
            </div>
            <div class="box-one">
              <span>较上周：</span>
              <span>{{ item.weekNum }}</span>
              <span
                ><i
                  :class="
                    item.weekNum > 0
                      ? 'el-icon-top t'
                      : item.weekNum < 0
                      ? 'el-icon-bottom b'
                      : ''
                  "
                ></i
              ></span>
            </div>
            <div class="box-one">
              <span>较上月：</span>
              <span>{{ item.monthNum }}</span>
              <span
                ><i
                  :class="
                    item.monthNum > 0
                      ? 'el-icon-top t'
                      : item.monthNum < 0
                      ? 'el-icon-bottom b'
                      : ''
                  "
                ></i
              ></span>
            </div>
            <div class="box-one">
              <span>较上年：</span>
              <span>{{ item.yearNum }}</span>
              <span
                ><i
                  :class="
                    item.yearNum > 0
                      ? 'el-icon-top t'
                      : item.yearNum < 0
                      ? 'el-icon-bottom b'
                      : ''
                  "
                ></i
              ></span>
            </div>
          </div>
        </div>
      </div>
      <!-- 商户区域分布情况 中国地图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span> 风险处置区域分布图 </span>
          </div>
        </div>

        <div class="map-charts">
          <Baidumap
            v-if="regionType >= 3 && areaName"
            v-loading="showbmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(9,32,57, 0.1)"
            :regionName="regionName"
            :flag="flag"
            :cityName="cityName"
            :areaName="areaName"
            :data="baidumapOptions"
          />
          <Pmap
            v-else
            v-loading="showmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(9,32,57, 0.1)"
            :regionName="regionName"
            :cityName="cityName"
            :flag="flag"
            :mapOptions="mapOptions"
          ></Pmap>
        </div>
      </div>
      <!-- 风险处置新增变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>风险处置新增变化趋势 </span>
          </div>

          <div class="echarts-but">
            <el-radio-group v-model="radio1">
              <el-radio-button
                v-for="item in radioData"
                :label="item.value"
                :key="item.value"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <div class="charts">
          <Line-chart
            v-loading="barChart1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :chartData="barChartData"
            :legendData="[
              '警告',
              '罚款',
              '没收违法所得',
              '吊销/暂扣执照',
              '责令停产停业',
              '行政拘留',
              '其他',
            ]"
            :units="unitData"
            :yAxis="['']"
            :yAxisIndex="['0']"
            :type="radio1"
          ></Line-chart>
        </div>
      </div>
      <!-- 风险处置累计变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>风险处置累计变化趋势 </span>
          </div>
          <div class="echarts-but">
            <el-radio-group v-model="radio2">
              <el-radio-button
                v-for="item in radioData"
                :label="item.value"
                :key="item.value"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <div class="charts">
          <Line-chart
            v-loading="lineChart2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :chartData="lineChartData"
            :legendData="[
              '警告',
              '罚款',
              '没收违法所得',
              '吊销/暂扣执照',
              '责令停产停业',
              '行政拘留',
              '其他',
            ]"
            :units="unitData2"
            :yAxis="['']"
            :yAxisIndex="['0']"
            :type="radio2"
          ></Line-chart>
        </div>
      </div>
      <!-- 风险处置按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>风险处置区域分布数量统计</span>
          </div>

          <div class="h-selct">
            <el-select v-model="hairpinType1" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in options"
                :key="item.haripinType"
                :label="item.name"
                :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
          >
            <el-table-column
              prop="regionName"
              label="地区"
              sortable
              width="200"
            >
            </el-table-column>
            <template>
              <el-table-column prop="numMoney" label="警告" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum1 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column prop="custodyMoney" label="罚款" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum2 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceMoney"
                label="没收违法所得"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum3 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceMoney"
                label="吊销/暂扣执照"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum4 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceMoney"
                label="责令停产停业"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum5 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column prop="insuranceMoney" label="行政拘留" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum6 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column prop="insuranceMoney" label="其他" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum7 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
            </template>
          </el-table>
          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
              >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 预付码按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>风险处置领域/行业分布数量统计</span>
          </div>
          <div class="h-selct">
            <el-select v-model="hairpinType2" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in options"
                :key="item.haripinType"
                :label="item.name"
                :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll2"
            v-loading="loading2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            row-key="industryId"
            lazy
            :load="loadNextNode2"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort2"
          >
            <el-table-column prop="industryName" label="领域/行业" sortable>
            </el-table-column>
            <template>
              <el-table-column prop="numMoney" label="警告" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum1 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column prop="custodyMoney" label="罚款" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum2 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceMoney"
                label="没收违法所得"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum3 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceMoney"
                label="吊销/暂扣执照"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum4 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceMoney"
                label="责令停产停业"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum5 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column prop="insuranceMoney" label="行政拘留" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum6 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column prop="insuranceMoney" label="其他" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum7 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
            </template>
          </el-table>
          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
              >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LineChart from "@/components/charts/line2"; // 四码折线图
import PieChart from "@/components/charts/PiePlace"; //饼图
import BarChart from "@/components/charts/bar";
import Baidumap from "@/components/baidumap/index.vue";
import Pmap from "@/components/charts/map"; //地图
import {
  resourcesStatistics,
  damagedAdd,
  damagedCumulative,
  damagedCityStatistics,
  damagedIndustryStatistics,
  regionalDistributionInfo,
} from "@/api/homeplace";

import { cardType } from "@/api/common";

export default {
  name: "HomePlace",
  props: {
    regionName: [String],
    areaName: [String],
    cityName: [String],
    flag: [String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
  },
  data() {
    return {
      showmap: true,
      showbmap: true,
      barChart1: false,
      lineChart2: false,
      hairpinType1: "",
      hairpinType2: "",
      radio1: "1",
      radio2: "1",
      radioData: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      isTowPie: false,
      tabData: [
        {
          name: "涉及商户",
          value: 1,
        },
        {
          name: "涉及人数",
          value: 2,
        },
        {
          name: "涉及笔数",
          value: 3,
        },
        {
          name: "涉及金额",
          value: 4,
        },
      ],
      pieChartData: {
        seriesData: [
          {
            name: "存管",
            value: "0",
          },
          {
            name: "保险",
            value: "0",
          },
        ],
      },
      unitData: ["次", "次", "次", "次", "次", "次", "次"],
      unitData2: ["次", "次", "次", "次", "次", "次", "次"],
      barChartDataAll: [],
      barChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "警告",
            data: [],
          },
          {
            name: "罚款",
            data: [],
          },
          {
            name: "没收违法所得",
            data: [],
          },
          {
            name: "吊销/暂扣执照",
            data: [],
          },
          {
            name: "责令停产停业",
            data: [],
          },
          {
            name: "行政拘留",
            data: [],
          },
          {
            name: "其他",
            data: [],
          },
        ],
      },
      lineChartDataAll: [],
      lineChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "警告",
            data: [],
          },
          {
            name: "罚款",
            data: [],
          },
          {
            name: "没收违法所得",
            data: [],
          },
          {
            name: "吊销/暂扣执照",
            data: [],
          },
          {
            name: "责令停产停业",
            data: [],
          },
          {
            name: "行政拘留",
            data: [],
          },
          {
            name: "其他",
            data: [],
          },
        ],
      },
      showAll: false,
      loading1: false,
      loading2: false,
      tableDataAll: [],
      tableData1: [],
      maps: new Map(),
      showAll2: false,
      tableDataAll2: [],
      tableData2: [],
      maps2: new Map(),
      forCodeData: [],
      baidumapOptions: [],
      mapOptions: [],
      options: [],
      liuchengdata: [
        {
          name: "警告",
          value: "0",
          com: "次",
        },
        {
          name: "罚款",
          value: "0",
          com: "次",
        },
        {
          name: "没收违法所得",
          value: "0",
          com: "次",
        },
        {
          name: "责令停产停业",
          value: "0",
          com: "次",
        },
        {
          name: "吊销/暂扣执照",
          value: "0",
          com: "次",
        },
        {
          name: "行政拘留",
          value: "0",
          com: "次",
        },
        {
          name: "其他",
          value: "0",
          com: "次",
        },
      ],
      newforCodeData: [
        {
          yestodayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
        },
        {
          yestodayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
        },
        {
          yestodayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
        },
        {
          yestodayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
        },
        {
          yestodayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
        },
        {
          yestodayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
        },
        {
          yestodayNum: 0,
          weekNum: 0,
          monthNum: 0,
          yearNum: 0,
        },
      ],
    };
  },
  computed: {
    costRequest() {
      return {
        areaName: this.areaName,
        regionName: this.regionName,
        cityName: this.cityName,
        flag: this.flag,
        regionId: this.regionId,
        regionType: this.regionType,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    radio1: function (val) {
      this.damagedAdd();
    },

    radio2: function (val) {
      this.damagedCumulative();
    },

    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },

    hairpinType1: function (val) {
      this.damagedCityStatistics();
    },
    hairpinType2: function (val) {
      this.damagedIndustryStatistics();
    },
  },
  methods: {
    intData() {
      this.cardType();
      this.resourcesStatistics();
      this.damagedAdd();
      this.damagedCumulative();
      this.damagedCityStatistics();
      this.damagedIndustryStatistics();
      this.getMapOptionData();
    },
    cardType() {
      cardType().then((res) => {
        this.options = res.data;
      });
    },
    getMapOptionData() {
      this.showbmap=true;
      this.showmap=true;
      regionalDistributionInfo({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          //this.mapOptions = res.data;

          let data = [];
          res.data.forEach((item) => {
            data.push({
              name: item.regionName,
              value: 0,
              warning: item.disposeNum1,
              fine: item.disposeNum2,
              illegalincome: item.disposeNum3,
              production: item.disposeNum4,
              license: item.disposeNum5,
              detention: item.disposeNum6,
              other: item.disposeNum7,
            });
          });
          this.mapOptions = data;
          this.showmap = false;
          if (this.regionType >= 3 && this.areaName) {
            this.baidumapOptions = data;
            this.showbmap = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    resourcesStatistics() {
      resourcesStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          //console.log('风险等级情况统计:',res)
          this.newforCodeData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    damagedAdd() {
      this.barChart1 = true;
      damagedAdd({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
      }).then((res) => {
        this.barChart1 = false;
        //console.log('风险等级新增变化趋势',res)
        this.barChartData = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "警告",
              data: res.data.disposeNum1,
            },
            {
              name: "罚款",
              data: res.data.disposeNum2,
            },
            {
              name: "没收违法所得",
              data: res.data.disposeNum3,
            },
            {
              name: "吊销/暂扣执照",
              data: res.data.disposeNum4,
            },
            {
              name: "责令停产停业",
              data: res.data.disposeNum5,
            },
            {
              name: "行政拘留",
              data: res.data.disposeNum6,
            },
            {
              name: "其他",
              data: res.data.disposeNum7,
            },
          ],
        };
      });
    },
    damagedCumulative() {
      this.lineChart2 = true;
      damagedCumulative({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio2,
      }).then((res) => {
        console.log("风险等级累计变化趋势", res);
        this.lineChart2 = false;
        this.lineChartData = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "警告",
              data: res.data.disposeNum1,
            },
            {
              name: "罚款",
              data: res.data.disposeNum2,
            },
            {
              name: "没收违法所得",
              data: res.data.disposeNum3,
            },
            {
              name: "吊销/暂扣执照",
              data: res.data.disposeNum4,
            },
            {
              name: "责令停产停业",
              data: res.data.disposeNum5,
            },
            {
              name: "行政拘留",
              data: res.data.disposeNum6,
            },
            {
              name: "其他",
              data: res.data.disposeNum7,
            },
          ],
        };
        // this.lineChartDataAll = res.data;
        // this.changeLineChartData();
      });
    },
    damagedCityStatistics() {
      let _this = this;
      this.loading1 = true;
      damagedCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        this.loading1 = false;
        this.tableDataAll = [];
        this.$nextTick(() => {
          this.tableData1 = res.data || [];
          this.tableDataAll = this.tableData1.slice(0, 10);
          this.showAllData();
        });
      });
    },
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    loadNextNode1(tree, treeNode, resolve) {
      let _this = this;
      const pid = tree.regionId;
      _this.maps.set(pid, { tree, treeNode, resolve });
      damagedCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
      }).then((res) => {
        resolve(res.data || []);
      });
    },
    damagedIndustryStatistics() {
      let _this = this;
      this.loading2 = true;
      damagedIndustryStatistics({
        hairpinType: this.hairpinType2,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        res.data.forEach((v) => {
          v.hasChildren = true;
        });
        this.loading2 = false;
        this.tableDataAll2 = [];
        this.$nextTick(() => {
          this.tableData2 = res.data || [];
          this.tableDataAll2 = this.tableData2.slice(0, 10);
          this.showAllData2();
        });
      });
    },
    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      let _this = this;
      const pid = tree.industryId;
      _this.maps2.set(pid, { tree, treeNode, resolve });
      damagedIndustryStatistics({
        hairpinType: this.hairpinType2,
        industryId: tree.industryId,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        resolve(res.data);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },
  },
  components: {
    LineChart,
    PieChart,
    BarChart,
    Baidumap,
    Pmap,
  },
  mounted() {
    if (sessionStorage.getItem("initParams") === "init") {
      this.intData();
    }
  },
  beforeDestroy() {
    window.sessionStorage.setItem("initParams", "init");
  },
};
</script>

<style lang="scss" scoped >
.HomePlace {
  // 四色码
  .idiot-one {
    height: 314px;
    margin: 0 30px 30px 30px;
    border: 1px solid #17688d;
    background: linear-gradient(
      to bottom,
      rgba(43, 202, 255, 0.1),
      #0000,
      rgba(43, 202, 255, 0.1)
    );
    .idiot-child-top {
      width: 100%;
      height: 160px;

      .idiot-child-top-box {
        width: 1064px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 40px;
        .idiot-child-top-pic {
          width: 49px;
          height: 52px;
          margin: 26px auto 2px;
          &.pic1 {
            background: url("../assets/imgs/homePlace/icon1.png") no-repeat;
          }
          &.pic2 {
            background: url("../assets/imgs/homePlace/icon2.png") no-repeat;
          }
          &.pic3 {
            background: url("../assets/imgs/homePlace/icon3.png") no-repeat;
          }
          &.pic4 {
            background: url("../assets/imgs/homePlace/icon4.png") no-repeat;
          }
          &.pic5 {
            background: url("../assets/imgs/homePlace/icon5.png") no-repeat;
          }
          &.pic6 {
            background: url("../assets/imgs/homePlace/icon6.png") no-repeat;
          }
          &.pic7 {
            background: url("../assets/imgs/homePlace/icon7.png") no-repeat;
          }
        }
        .idiot-child-top-name {
          margin: 0 auto 12px;
          width: 73px;
          height: 24px;
          font-size: 14px;
          color: #00c6ff;
          text-align: center;
          line-height: 24px;
          background: url("../assets/imgs/homeSynthesizeSupervise/icon-bg.png")
            no-repeat;
          &.long {
            width: 105px;
            background: url("../assets/imgs/homePlace/icon-bg.png") no-repeat;
          }
        }
        .idiot-child-top-num {
          width: 120px;
          height: 100%;
          background-image: -webkit-linear-gradient(bottom, #2285ff, #13caff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;
          line-height: 1;
          .idiot-child-top-num-span {
            font-size: 14px;
          }
        }
      }
    }
    .box-idiot {
      display: flex;
      justify-content: space-around;
    }
    .box {
      width: 16.6%;
      text-align: center;
      border: 0;
      border-right: 1px solid #ddd;
      border-image: linear-gradient(
          #0000,
          #0000,
          #17688d,
          #17688d,
          #0000,
          #0000
        )
        30 30;

      .box-one {
        display: flex;
        justify-content: center;
        font-size: 14px;
        line-height: 32px;
        span:nth-child(1) {
          color: #9fa1a7;
        }
        span:nth-child(2) {
          color: #eee;
          width: 60px;
          text-align: left;
        }
        span:nth-child(3) {
          color: #eee;
          width: 10px;
          font-size: 16px;
          .t {
            color: #ffba00;
            font-weight: bold;
          }
          .b {
            color: #59d2f3;
            font-weight: bold;
          }
        }
      }
    }
  }
  .pie-box {
    background: url("../assets/imgs/pie-bg3.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0;
    .echarts-box {
      position: relative;
    }
    .pie-charts {
      background: url("../assets/imgs/pie-bg2.png") no-repeat center center;
    }
  }
  .tab-select-btn {
    position: absolute;
    top: 6px;
    right: 0;
  }
}
</style>
